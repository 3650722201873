<template>
  <a-modal
    width="68%"
    title="用户列表"
    :visible="visibleModal"
    @ok="handleOk"
    @cancel="handleCancel"
    :destroyOnClose="false"
    :dialogStyle="{marginTop:'-50px'}"
  >
    <!-- 数据展示 -->
    <!-- :row-selection="rowSelection" -->
    <a-table
      :loading="loading"
      :size="tableSize"
      rowKey="id"
      :columns="columns"
      :data-source="list"
      :pagination="false"
      :bordered="tableBordered"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: rowselection }"
    >
      <!-- <span slot="sex" slot-scope="text, record">
        <dict-tag :options="dict.type['sys_user_sex']" :value="record.sex" />
      </span>
      <span slot="isNotice" slot-scope="text, record">
        <dict-tag :options="dict.type['sys_notice_status']" :value="record.isNotice" />
      </span>
      <span slot="status" slot-scope="text, record">
        <dict-tag :options="dict.type['sys_enable_disable']" :value="record.status" />
      </span>
      <span slot="createTime" slot-scope="text, record">
        {{ parseTime(record.createTime) }}
      </span> -->
    </a-table>
    <!-- 分页 -->
    <template slot="footer">
      <div style="height: 45px" class="footer">
        <a-button type="primary" @click="enter"> 确定 </a-button>
        <a-pagination
          class="ant-table-pagination"
          show-size-changer
          show-quick-jumper
          :current="queryParam.pageNum"
          :total="total"
          :page-size="queryParam.pageSize"
          :showTotal="(total) => `共 ${total} 条`"
          @showSizeChange="onShowSizeChange"
          @change="changeSize"
        />
      </div>
    </template>
  </a-modal>
</template>

<script>
import { pageUser, listUser, delUser } from '@/api/biz/user'
// import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
export default {
  data() {
    return {
      ModalText: 'Content of the modal',
      visibleModal: false,
      confirmLoading: false,
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        mobile: null,
        email: null,
        nickname: null,
        sex: null,
        invitationCode: null,
        isNotice: null,
        status: null,
        pageNum: 1,
        pageSize: 10,
      },
      columns: [
        {
          title: '手机号',
          dataIndex: 'mobile',

          align: 'center',
        },
        {
          title: '邮箱',
          dataIndex: 'email',

          align: 'center',
        },
        {
          title: '昵称',
          dataIndex: 'nickname',

          align: 'center',
        },
        {
          title: '性别',
          dataIndex: 'sex',
          scopedSlots: { customRender: 'sex' },

          align: 'center',
        },
        {
          title: '生日',
          dataIndex: 'birthday',

          align: 'center',
        },
        {
          title: '邀请码',
          dataIndex: 'invitationCode',

          align: 'center',
        },
        {
          title: '是否推送',
          dataIndex: 'isNotice',
          scopedSlots: { customRender: 'isNotice' },

          align: 'center',
        },
        {
          title: '用户状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },

          align: 'center',
        },
        {
          title: '注册时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
        },
      ],
      obj: [],
    }
  },
  computed: {
    rowSelection() {
      return {
        rowselection: (selectedRowKeys, selectedRows) => {
          //  console.log(selectedRows);

          // this.obj = this.selectedRows
        },
      }
    },
  },
  created() {
    this.getList()
    this.updateColumns()
  },
  methods: {
    show() {
      this.visibleModal = true
    },
    handleOk(e) {
      this.ModalText = 'The modal will be closed after two seconds'
    },
    handleCancel() {
      this.visibleModal = false
    },
    getList() {
      this.loading = true
      pageUser(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        mobile: undefined,
        email: undefined,
        nickname: undefined,
        sex: undefined,
        invitationCode: undefined,
        isNotice: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10,
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map((id) => data.push({ id: id }))
          return delUser(data).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        },
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download(
            'biz/user/export',
            {
              ...that.queryParam,
            },
            `用户信息_${new Date().getTime()}.xlsx`
          )
        },
        onCancel() {},
      })
    },
    rowselection(selectedRowKeys, selectedRows) {
      // console.log('ddddddd',selectedRowKeys,selectedRows)
      if (this.selectedRows.length > 0) {
        const key = 'id' // 填rowkey值 就是你在a-table标签里填的rowkey属性值是啥，这里就填啥
        this.selectedRowKeys = [selectedRowKeys.filter((item) => item !== this.selectedRowKeys[0])[0]]
        this.selectedRows = [selectedRows.filter((item) => item[key] !== this.selectedRows[0][key])[0]]
        this.obj = this.selectedRows

      } else {
        this.selectedRowKeys = [selectedRowKeys[0]]
        this.selectedRows = [selectedRows[0]]
        this.obj = this.selectedRows

      }
      // console.log(this.selectedRows)
      // this.obj = this.selectedRows
      
    },
    enter() {
      // console.log(this.obj)
      this.$emit('handleEnter', this.obj )
      this.visibleModal = false
    },
  },
}
</script>

<style scoped lang="less">
/deep/.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>